<template>
  <b-container fluid class="mb-6 mt-6 lesson-detail-page">
    <b-overlay :show="loading">
      <b-row>
        <b-col cols="3" v-if="groupCoach">
          <div class="card card-custom gutter-b lesson-detail-page__left-panel">
            <div class="card-body p-0">
              <b-card
                overlay
                class="lesson-detail-page__left-panel__image-card"
                :img-src="groupCoach.image.url || null"
                :img-height="200"
              >
                <b-card-text
                  class="lesson-detail-page__left-panel__image-card-title"
                >
                  {{ groupCoach.name }}
                </b-card-text>
              </b-card>
              <div class="lesson-detail-page__left-panel__information">
                <b-container>
                  <b-row>
                    <b-col class="px-6">
                      <div class="d-flex">
                        <b-button
                          v-if="isWriteAllPermission || isWriteOwnerPermission"
                          size="sm"
                          class="btn-edit mr-4"
                          @click="goToEdit"
                        >
                          <span class="svg-icon svg-icon-sm mr-0">
                            <inline-svg
                              class="svg-icon mr-0"
                              src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                            />
                          </span>
                        </b-button>
                        <b-button
                          pill
                          size="sm"
                          class="btn-status px-7"
                          :class="getStatus(groupCoach.status)"
                          >{{ getStatus(groupCoach.status) }}
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="px-6">
                      <label for="" class="text-gray">Mã nhóm</label>
                      <div>{{ groupCoach.code }}</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="px-6">
                      <label for="" class="text-gray">Số lượng</label>
                      <div>
                        <span class="svg-icon svg-icon-xl">
                          <inline-svg
                            class="svg-icon"
                            src="/media/svg/icons/Neolex/Basic/Library.svg"
                          />
                        </span>
                        {{ groupCoach.maxMember }}
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="px-6">
                      <label for="" class="text-gray">Coach</label>
                      <div class="d-flex align-items-center">
                        <avatar
                          size="40px"
                          :text="groupCoach.healthCoachName"
                          :rounded="true"
                          :src="groupCoach.healthCoachImage.url"
                        />
                        <div class="d-flex flex-column ml-5">
                          <p class="mb-0 font-weight-bolder">
                            {{ groupCoach.healthCoachName }}
                          </p>
                          <p class="mb-0 text-gray">
                            {{ groupCoach.healthCoachCode }}
                          </p>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <b-card class="h-100">
            <b-row class="align-items-end">
              <b-col cols="4">
                <basic-input
                  label="Tên khách hàng"
                  placeholder="Nhập tên khách hàng"
                  name="name"
                  class="coach-filter"
                  :debounce="2000"
                  :value.sync="filter.name"
                  @v-modal:update="searchDebounce"
                />
              </b-col>
              <b-col class="text-right">
                <b-button
                  class="btn btn-success ml-3"
                  type="button"
                  @click="goToZalo"
                >
                  <span class="svg-icon">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Chatting/comment.svg"
                    />
                  </span>
                  Nhắn tin nhóm
                </b-button>
              </b-col>
            </b-row>
            <table-patient
              :patients="patients"
              :paging="paging"
              :show-paging="true"
              :show-actions="false"
              @sortBy="sortRequest"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { GET_GROUP_COACH } from '@/core/services/store/coach.module';
const { mapActions, mapState } = createNamespacedHelpers('coach');
export default {
  name: 'CoachDetail',
  components: {
    'table-patient': () => import('./components/TablePatient.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentTab: 'START',
      loading: false,
      statuses: [
        {
          name: 'Bình thường',
          value: 1,
        },
        {
          name: 'Đối tượng ưu tiên',
          value: 2,
        },
        {
          name: 'Tạm ngưng',
          value: 0,
        },
        {
          name: 'Tranh chấp',
          value: 3,
        },
      ],
      filter: {
        name: null,
      },
      patients: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      sort: {
        by: null,
        order: null,
      },
    };
  },
  computed: {
    ...mapState(['groupCoach']),
    searchParams() {
      return {
        name: this.filter.name || null,
        orderByExtend: this.sort.by
          ? `${this.sort.by} ${this.sort.order}`
          : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        trainingGroupId: this.id,
      };
    },
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
  },
  watch: {
    'paging.page': function () {
      this.getPatients();
    },
    'paging.pageSize': function () {
      this.getPatients();
    },
  },
  async created() {
    this.loading = true;
    await Promise.all([this.getGroupCoachDetail(), this.getPatients(false)]);
    this.loading = false;
  },
  methods: {
    ...mapActions({ GET_GROUP_COACH }),
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        this.veeFields[ref].dirty &&
        this.veeFields[ref].validated
      ) {
        return this.veeFields[ref].valid;
      }
      return null;
    },
    async getGroupCoachDetail() {
      const res = await this.GET_GROUP_COACH({ id: this.id });
      if (res.error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: res.error,
        });
      }
    },
    async getPatients(loading = true) {
      try {
        loading && this.$store.commit('context/setLoading', true);
        const { meta, data, error } = await this.$api.get(
          `/TrainingGroup/Accounts`,
          {
            params: this.searchParams,
          },
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.patients = data;
        this.paging.total = meta.total;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        loading && this.$store.commit('context/setLoading', false);
      }
    },
    resetFilter() {
      const isValid = !Object.values(this.filters).every((o) => o === null);
      if (!isValid) return;

      this.filters = {
        code: null,
        name: null,
        startAge: null,
        endAge: null,
        startDate: null,
        endDate: null,
        status: null,
      };
      this.search();
    },
    search() {
      if (this.currentTab === 'START') {
        this.$refs.inProgress.getPatientOfGroup();
      } else {
        this.$refs.finished.getPatientOfGroup();
      }
    },
    goToEdit() {
      this.$router.push({
        name: 'coach_edit',
        params: {
          id: this.id,
        },
      });
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'inactive';
        case 1:
          return 'active';

        default:
          return 'draft';
      }
    },
    formatDate(date) {
      const isValid = this.$moment(date, 'MM/DD/YYYY HH:mm:ss').isValid();
      if (!isValid) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },
    searchDebounce(value) {
      this.filter.name = value;
      this.getPatients();
    },
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.getPatients();
    },
    goToZalo() {
      window.open(this.groupCoach.linkZalo);
    },
  },
};
</script>
<style lang="scss" scoped>
.img-header {
  height: 140px;
}
.shadow-none {
  box-shadow: none !important;
}
.text-gray {
  color: #b5b5c3;
}
.mw-400 {
  min-width: 400px;
}
.tab-active {
  border-bottom: 4px solid #008479;
}
.status {
  background: #21a567;
  border-radius: 20px;
  color: #fff;
  padding: 6px 20px;
}

.lesson-detail-page {
  .lesson-detail-page__left-panel {
    height: 100%;

    .lesson-detail-page__left-panel__image-card {
      margin-bottom: 20px;
      min-height: 200px;

      .card-body {
        padding: 0;
        min-height: 200px;
      }

      .lesson-detail-page__left-panel__image-card-title {
        height: 60px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        padding: 10px;
        overflow: hidden;
      }
    }

    .lesson-detail-page__left-panel__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      .lesson-detail-page__left-panel__actions__button {
        height: 30px;
        width: 30px;

        .lesson-detail-page__left-panel__actions__button__icon {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .lesson-detail-page__right-panel {
    height: 100%;
  }
}
</style>

<style lang="scss">
.group-detail {
  .subheader-filters {
    margin-bottom: -10px;
    height: 50px;
  }
}

.btn-edit {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}

.btn-status {
  color: #fff !important;
  text-transform: capitalize;
  position: relative;

  &.active {
    background-color: #21a567 !important;
    border-color: #21a567 !important;
  }

  &.inactive {
    background-color: #ff5756 !important;
    border-color: #ff5756 !important;
  }

  &.draft {
    background-color: #888c9f !important;
    border-color: #888c9f !important;
  }
}

.form-group {
  margin-bottom: 0;
}
</style>
